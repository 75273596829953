import React, { useCallback, useEffect, useRef, useState } from 'react';
import checkIcon from '../../assets/images/check-circle.png';
import style from './style.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareModalPage from '../ShareModalPage';
import { sendAnalyticsEvent, sourseIcon } from '../../helpers/helpers';
import { FEEDBACK_TYPES } from '../../constants/feedbackTypes';
import classnames from 'classnames';

const plotColors = {
  general: '#5DBCD2',
  zomato: '#cb202d',
  facebook: '#3C5A99',
  google: '#F4B400',
  tripadvisor: '#00af87',
  foursquare: '#5DBCD2',
  talabat: '#F36321',
  hungerstation: '#60503D',
  hungerStation: '#60503D',
  reserveout: '#E31C21',
  booking: '#47A1DE',
  expedia: '#EEC218',
};

const SocialSharingPage = ({
  sharingSources,
  shareQuestion,
  shareExploration,
  thanksForFeedback,
  sharingLink,
  shareButton,
  clipboardText,
  backgroundColor,
  fontColor,
  enableSharingWithRating,
  redirectTomessage,
  redirectCountdown,
  feedbackDetectedСopied,
  feedbackNotDetected,
  oneSource,
  feedbackType
}) => {
  const [selectedSource, setSelectedSource] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const [autoShare, setAutoShare] = useState(oneSource);
  const shareButtonReference = useRef(null);

  // eslint-disable-next-line
  useEffect( () => {
    enableSharingWithRating && shareHandler()
  });

  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  const onSelectSource = (e) => {
    const selected = e.currentTarget.dataset.name;
    setSelectedSource(selected);
    scrollIntoView();
  };

  const onShare = async () => {
    setShowShareModal(true);

    sendAnalyticsEvent('share_button_clicked', 'Social', 'Clicked Share Button', selectedSource);

    setTimeout(async () => {
      setShowShareModal(false);
      await sharingLink(selectedSource);
      setSelectedSource('');
    }, 5000);
  };

  const autoDirect = useCallback(async () => {
    if (!clipboardText) {
      await sharingLink(sharingSources[0].title);
    } else {
      if (!isSafari) {
        setShowShareModal(true);
        setTimeout(async () => {
          setShowShareModal(false);
          await sharingLink(sharingSources[0].title);
          setSelectedSource('');
        }, 5000);
      }
    }
  }, [clipboardText, isSafari, sharingLink, sharingSources])

  const scrollIntoView = () => {
    shareButtonReference.current.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
  };

  const shareHandler = useCallback(async () => {
    if (autoShare) {
      if (!isSafari) {
        navigator.clipboard.writeText(clipboardText);
      }
      setSelectedSource(sharingSources[0].title);
      setAutoShare(false);
      await autoDirect();
    }
  }, [autoDirect, autoShare, clipboardText, isSafari, sharingSources])

  return (
    <div className={style.socialSharePageAndEditorWrapper}>
      <div
        className={style.pageWrapper}
        style={{ backgroundColor: backgroundColor }}
      >
        <img src={checkIcon} alt='Check icon' className={style.checkIcon} />
        <p className={style.thanksForSupport} style={{ color: fontColor }}>
          {thanksForFeedback}
        </p>
        { feedbackType !== FEEDBACK_TYPES.kiosk && enableSharingWithRating !== 0 && sharingSources.length ? (
          <>
            <p className={style.gladMessage} style={{ color: fontColor }}>
              {shareQuestion}
            </p>
            <p className={style.shareQuestion} style={{ color: fontColor }}>
              {shareExploration}
            </p>
            <div className={style.socialLinksWrapper}>
              {sharingSources.map((source) => {
                let socialName = source.title.toLowerCase();
                let sourceColor = plotColors[socialName]
                  ? plotColors[socialName]
                  : plotColors.general;

                return (
                  source.display && (
                    <div
                      key={source.title}
                      className={style.socialLinkIconWrapper}
                      data-source={source.title.toLowerCase()}
                    >
                      <div
                        className={style.socialLink}
                        style={{
                          color: `${sourceColor}`,
                          backgroundColor: `${sourceColor}`,
                        }}
                      ></div>
                      <div
                        className={classnames(
                          style.radioButtonBackground,
                          plotColors[socialName]
                            ? style[source.title]
                            : style.general
                        )}
                      />
                      <label
                        className={style.sourceNameAndIcon}
                        style={{
                          color: `${sourceColor}`,
                          border: `1px solid ${sourceColor}`,
                        }}
                      >
                        <input
                          type='radio'
                          className={classnames(
                            style.radioStyle,
                            plotColors[socialName]
                              ? style[source.title]
                              : style.general
                          )}
                          data-name={source.title}
                          value={source.title}
                          onChange={(e) => onSelectSource(e)}
                          checked={source.title === selectedSource}
                        />
                        {sourseIcon(source.title, sourceColor, '20px', '20px')}
                        <span className={style.sourceName}>{source.title}</span>
                      </label>
                    </div>
                  )
                );
              })}
            </div>
            {clipboardText ? (
              <CopyToClipboard text={clipboardText}>
                <button
                  ref={shareButtonReference}
                  disabled={!selectedSource}
                  className={classnames(style.shareButton)}
                  onClick={onShare}
                >
                  {shareButton}
                </button>
              </CopyToClipboard>
            ) : (
              //need to hide clipboard window for safari browser
              <button
                ref={shareButtonReference}
                disabled={!selectedSource}
                className={classnames(
                  style.shareButton,
                  !selectedSource && style.shareButtonDisabled
                )}
                onClick={onShare}
              >
                {shareButton}
              </button>
            )}

            {showShareModal && (
              <ShareModalPage
                visible={showShareModal}
                redirectTomessage={redirectTomessage}
                redirectCountdown={redirectCountdown}
                sharedMessage={
                  clipboardText ? feedbackDetectedСopied : feedbackNotDetected
                }
                source={selectedSource}
                footer={null}
                isSafari={isSafari}
              />
            )}
          </>
        ) : ''}
      </div>
    </div>
  );
};

export default SocialSharingPage;
