import React from 'react';
import style from './style.module.css';
import PropTypes from 'prop-types';
import LanguageFilter from '../LanguageFilter';


const HeaderTemplate = ({ language, onSelectLanguage, languagesOptions, showDetailsPage}) => {
    return (
        <div style={{backgroundColor: 'transparent'}}>
            <div className={style.headerTemplateWrapper}>
              {!showDetailsPage && <LanguageFilter value={language} onSelect={onSelectLanguage} languagesOptions={languagesOptions} />}
            </div>
        </div>
    );
};
HeaderTemplate.propTypes = {
    language: PropTypes.string.isRequired,
    pageTitle: PropTypes.string,
    onSelectLanguage: PropTypes.func.isRequired,
    languagesOptions: PropTypes.array.isRequired,
};

export default HeaderTemplate;

