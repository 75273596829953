import React from 'react';
import { Select } from 'antd';
import style from './style.module.css';
import PropTypes from 'prop-types';
import ISO6391 from 'iso-639-1';
import { sendAnalyticsEvent } from '../../helpers/helpers';

const Option = Select.Option;

const LanguageFilter = ({ onSelect, value, languagesOptions }) => {
  const languagesForSelection = languagesOptions.map(langCode => ({
    value: langCode,
    title: ISO6391.getNativeName(langCode)
  }));

  const handleDropdownChange = (open) => {
    if (open) {
      sendAnalyticsEvent('language_dropdown_opened', 'Dropdown', 'Opened Language Selector', 'Feedback Language Selector');
    }
  };

  const handleSelection = (value) => {
    sendAnalyticsEvent('language_selected', 'Option', 'Selected Language', value);

    onSelect(value);
  };

  return (
    <div id="area">
      <Select
        id="feedback-language-selector"
        value={value}
        className={style.filterLanguage}
        onSelect={handleSelection}
        getPopupContainer={() => document.getElementById('area')}
        onDropdownVisibleChange={handleDropdownChange}
      >
        {languagesForSelection.map(option => (
          <Option key={option.value} value={option.value} className={style.languageSelector}>
            {option.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};

LanguageFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  languagesOptions: PropTypes.array.isRequired
};

export default LanguageFilter;
